import React, { useEffect, useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import
    {
        encryptData,
        serverLink,
        showAlert,
    } from "../../resources/url";
import { setApplicationLoginDetails } from "../../actions/setactiondetails";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../common/pagename/pagename";
import Loader from "../common/loader/loader";

function ApplicationRecoverPassword(props)
{
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const token = window.location.href.split('/')[6]
    const [login, setLogin] = useState({
        Password: "",
        cPassword: "",
        EmailAddress: ""
    });



    const ValidateToken = async () =>
    {
        await axios
            .get(`${serverLink}application/forget_password/validate_token/${token}`)
            .then((res) =>
            {
                if (res.data.length === 0)
                {
                    showAlert("Error", "The link has expired, kindly send another email to reset your password", "error").then(() =>
                    {
                        navigate('/admission/application/login')
                    })
                } else
                {
                    setLogin({
                        ...login,
                        EmailAddress: res.data[0].EmailAddress
                    })
                }
                setLoading(false);
            })
            .catch((err) =>
            {
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() =>
    {
        ValidateToken();
    }, []);


    const handleReset = async (e) =>
    {
        e.preventDefault();
        if (login.Password !== login.cPassword)
        {
            toast.error('Password does not match');
            return;
        }

        const formData = {
            Password: encryptData(login.Password),
            EmailAddress: login.EmailAddress
        }

        await axios.patch(`${serverLink}application/forget_password/change_password/${token}`, formData)
            .then((result) =>
            {
                if (result.data.message === "success")
                {
                    toast.success('Your password have been changed successfully.');
                    navigate('/admission/application/login')
                } else
                {
                    toast.error('Please try again.')
                }
            }).catch((e) =>
            {
                toast.error('Please try again.')
                console.log('NETWORK ERROR')
            })

    }

    const onEdit = (e) =>
    {
        setLogin({
            ...login,
            [e.target.id]: e.target.value
        })
    };
    return loading ? (
        <Loader />
    ) : (
        <>
            <PageName page={"Reset Password"} />
            <PageTitle
                title={"Reset Password"}
                breadCrumbs={["Home", "Application", "Reset Password"]}
            />

            <div className="login-area pt-100 pb-70">
                <div className="container">
                    <div className="login">
                        <h3>Reset Password</h3>
                        <form onSubmit={handleReset}>
                            <div className="form-group">
                                <label htmlFor="Password">New Password</label>
                                <input
                                    type="password"
                                    id="Password"
                                    className="form-control"
                                    placeholder="Password*"
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    id="cPassword"
                                    className="form-control"
                                    placeholder="Confirm Password*"
                                    onChange={onEdit}
                                />
                            </div>
                            <button type="submit" className="default-btn btn active">
                                Change Password
                            </button>

                            <Link to="/admission/application/login">
                                Login
                            </Link>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) =>
{
    return {
        applicationLogin: state.ApplicationLoginDetails,
    };
};
const mapDispatchToProps = (dispatch) =>
{
    return {
        setOnApplicationLoginDetails: (p) =>
        {
            dispatch(setApplicationLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRecoverPassword);
