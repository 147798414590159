import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/url";

import { toast } from "react-toastify";
import { setApplicationLoginDetails, setGeneralDetails } from "../../actions/setactiondetails";
import { connect } from "react-redux";
import Sidebar from "./sidebar";

const GuardianInformation = (props) => {
    const navigate = useNavigate();
    const [userData] = useState(props.applicationLogin);
    const [guardianData, setGuardianData] = useState(
        props.applicant_data.guardian
    );
    const [guardian, setGuardian] = useState({
        ApplicationID: props.applicationData.EntryID,
        FirstName: "",
        MiddleName: "",
        Surname: "",
        PhoneNumber: "",
        EmailAddress: "",
        Address: "",
        Gender: "",
        EntryID: ""
    });

    const [shouldSave, setShouldSave] = useState(false);

    const handleChange = (e) => {
        setGuardian({ ...guardian, [e.target.id]: e.target.value });
        setShouldSave(true);
    };

    const allApplicationData = async () => {
        await axios
            .get(
                `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
            )
            .then((response) => {
                props.setOnGeneralDetails(response.data);
                setGuardianData(response.data.guardian);
                if (response.data.guardian.length > 0) {
                    setGuardian(response.data.guardian[0])
                    setShouldSave(true)
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        for (let value in guardian) {
            if (guardian.hasOwnProperty(value) && value !== "MiddleName" && guardian.hasOwnProperty(value) && value !== "EntryID") {
                if (guardian[value] === "") {
                    toast.error(`${value} can't be empty`);
                    return false;
                }
            }
        }

        if (userData.EmailAddress === guardian.EmailAddress) {
            toast.error("please provide a correct guardian email")
            return false
        }
        if (userData.PhoneNumber.trim() === guardian.PhoneNumber.trim()) {
            toast.error("please provide a correct guardian phone number")
            return false
        }

        if (shouldSave) {
            toast.info(`Submitting... Please wait!`);
            if (guardian.EntryID === "") {
                await axios
                    .post(`${serverLink}application/guardian/info`, guardian)
                    .then((res) => {
                        if (res.data.message === "success") {
                            toast.success(`Guardian Information Submitted Successfully`);
                            allApplicationData();
                            onNext();
                        } else
                            toast.error(
                                `Something went wrong. Please check your connection and try again!`
                            );
                    })
                    .catch((error) => {
                        console.log("NETWORK ERROR", error);
                    });
            } else {
                await axios
                    .patch(`${serverLink}application/guardian/update/info`, guardian)
                    .then((res) => {
                        if (res.data.message === "success") {
                            toast.success(`Guardian Information Submitted Successfully`);
                            allApplicationData();
                            onNext();
                        } else
                            toast.error(
                                `Something went wrong. Please check your connection and try again!`
                            );
                    })
                    .catch((error) => {
                        console.log("NETWORK ERROR", error);
                    });
            }
        }
    };

    const onPrevious = () => {
        navigate("/admission/application/undergraduate/jamb-result");
    };

    const onNext = () => {
        navigate("/admission/application/undergraduate/documents-uploads");
    };

    useEffect(() => {
        allApplicationData();
    }, [])
    return (
        <>
            <div className="container">
                <div className="row pb-5">
                    <Sidebar userData={userData} />
                    <div className="col-lg-9 mb-5">
                        {/* {guardianData.length < 1 && ( */}
                        <form className="login bg-white shadow p-5" onSubmit={handleSubmit}>
                            <h5>Guardian Information</h5>
                            <div className="row col-md-12">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="FirstName">
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="FirstName"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={guardian.FirstName}
                                            // required
                                            onChange={handleChange}
                                        />
                                        <span className="focus-border" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="MiddleName">Middle Name</label>
                                        <input
                                            type="text"
                                            id="MiddleName"
                                            className="form-control"
                                            placeholder="Middle Name"
                                            value={guardian.MiddleName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="Surname">
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="Surname"
                                            value={guardian.Surname}
                                            className="form-control"
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="PhoneNumber">
                                            Phone Number <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            id="PhoneNumber"
                                            value={guardian.PhoneNumber}
                                            className="form-control"
                                            placeholder="Phone Number"
                                            // required
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="EmailAddress">
                                            Email <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="EmailAddress"
                                            value={guardian.EmailAddress}
                                            className="form-control"
                                            placeholder="Email Address"
                                            // required
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="form-group">
                                        <label htmlFor="Gender">
                                            Gender <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            id="Gender"
                                            className="form-control"
                                            value={guardian.Gender}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="Address">
                                            Address <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            id="Address"
                                            className="form-control"
                                            placeholder="Address"
                                            defaultValue={guardian.Address}
                                            rows="3"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="filters-group mb-lg-4 text-center">
                                        <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                        <button className="btn btn-dark w-50  active" type="submit">  Save and Continue</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* )} */}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicant_data: state.GeneralDetails,
        applicationData: state.ApplicationDataDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GuardianInformation);

//export default GuardianInformation;
