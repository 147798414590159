import React, { useEffect, useState } from "react";
import {
    setCourseListDetails,
} from "./actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { decryptData, serverLink } from "./resources/url";
import PageRoutes from "./component/pageroutes/pageroutes";
import DataLoader from "./component/common/dataLoader/dataLoader";

function App(props) {
  const [isLoading, setIsLoading] = useState(
      !(!props.courseList.length > 0)
  );


  const getData = async () => {
    await axios
        .get(`${serverLink}academics/course/view/list`)
        .then((response) => {
          props.setOnCourseListDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
      <div className="App">{isLoading ? <DataLoader /> : <PageRoutes />}</div>
  );
}
const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnCourseListDetails: (p) => {
      dispatch(setCourseListDetails(p));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
