import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo.png";
import WhyUs from "../../../images/facilities/why-us.jpg"
import
  {
    formatDateAndTime,
    projectAddress, projectEmail, projectFacebook, projectInstagram, projectLinkedIn, projectLogo,
    projectName,
    projectPhone, projectStaffURL, projectStudentURL, projectTwitter, projectURL, projectVision,
  } from "../../../resources/url";

export default function Footer()
{
  return (
    <>
      <footer className="space-pt bg-overlay-black-90 bg-holder footer mt-n5">
        <div className="container pt-5">
          <div className="row pb-5 pb-lg-6 mb-lg-3">
            <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0 pr-lg-5">
              <a href="/">
                <img className="img-fluid mb-3 footer-logo" src={projectLogo} alt={projectName} />
              </a>

              <p className="text-white">{projectVision}</p>

              <h5 className="text-white mb-2 mb-sm-4">Follow Us</h5>
              <div className="social-icon social-icon-style-02">
                <ul>
                  <li><a href={projectFacebook}><i className="fab fa-facebook-f" /></a></li>
                  <li><a href={projectTwitter}><i className="fab fa-twitter" /></a></li>
                  <li><a href={projectLinkedIn}><i className="fab fa-linkedin-in" /></a></li>
                  <li><a href={projectInstagram}><i className="fab fa-instagram" /></a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2 mb-4 mb-lg-0">
              <h5 className="text-white mb-2 mb-sm-4">Useful Link</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href={`${projectURL}course-finder`}>Course Finder</a></li>
                  <li><a className="text-white" href={projectStudentURL}>Student Portal</a></li>
                  <li><a className="text-white" href={projectStaffURL}>Staff Portal</a></li>
                  <li><a className="text-white" href={`${projectURL}contact-us`}>Contact Us</a></li>

                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2 mb-4 mb-sm-0">
              <h5 className="text-white mb-2 mb-sm-4">Useful Link</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href={`${projectURL}`}>University Website</a></li>
                  <li><a className="text-white" href={`${projectURL}about-us`}>About Us</a></li>
                  <li><a className="text-white" href={`${projectURL}fees`}>Course Fees</a></li>
                  <li><a className="text-white" href={`${projectURL}academic-calender`}>Academic Calender</a></li>

                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <h5 className="text-white mb-2 mb-sm-4">Contact Us</h5>
              <p className="text-white">{projectName}</p>
              <div className="footer-contact-info">
                <div className="contact-address mt-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt" />
                    <p className="ml-3 mb-0 text-white">{projectAddress}</p>
                  </div>
                  <div className="contact-item">
                    <i className="fas fa-microphone-alt" />
                    <p className="mb-0 font-weight-bold ml-3"><a className="text-white" href={`tel:${projectPhone}`}>{projectPhone}</a></p>
                  </div>
                  <div className="contact-item mb-0">
                    <i className="fas fa-headset" />
                    <a className="text-white ml-3 text-white" href={`mailto:${projectEmail}`}>{projectEmail}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-dark py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center">
                <p className="mb-0 text-white">©Copyright {formatDateAndTime(new Date(), 'year_only')} {projectName} All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-to-top" className="back-to-top" onClick={() => window.scrollTo(0, 0)}>up</div>
    </>
  );
}
