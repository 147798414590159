import React, { useEffect, useState } from "react";
import
{
    setApplicationDataDetails,
    setApplicationLoginDetails,
} from "../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import
{
    encryptData,
    formatDate,
    formatDateAndTime,
    projectAddress,
    projectEmail,
    projectLogo,
    projectName,
    projectPhone,
    projectViceChancellor,
    serverLink,
    shortCode,
} from "../../resources/url";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import PageName from "../common/pagename/pagename";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
// import AlAnsarAdmissionLetter from "../admission-letter/al-ansar-admission";
import Loader from "../common/loader/loader";

function ApplicationDashboard(props)
{
    const navigate = useNavigate();
    const [userData] = useState(props.applicationLogin);
    const [applicationData, setApplicationData] = useState([]);
    const [showApplicationModal, setShowApplicationModal] = useState(false);

    const handleCloseApplicationModal = () => setShowApplicationModal(false);
    const handleShowApplicationModal = () => setShowApplicationModal(true);
    const [admissionLetter, setAdmissionLetter] = useState([]);
    const [appInfo, setappInfo] = useState([]);
    const [facultyDetails, setfacultyDetails] = useState([])
    const [decision, setDecision] = useState({})
    const [showAdmissionLetter, setShowAdmissionLetter] = useState(false);
    const componentRef = useRef();
    const [sendData, setSendData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [paymentData, setPaymentData] = useState([])
    const getApplicationRecord = async () =>
    {
        props.setOnApplicationDataDetails("");
        toast.info("Loading data. Please wait")
        await axios.get(`${serverLink}application/applicant/application/list/${userData.EntryID}`)
            .then((response) =>
            {
                setApplicationData(response.data);
                setIsLoading(false)
            })
            .catch((error) =>
            {
                console.log("NETWORK ERROR", error);
            }).finally(() =>
            {
                setIsLoading(false)
            })

        await axios.get(`${serverLink}application/applicant/payment/history/${userData.EntryID}`)
            .then((response) =>
            {
                setPaymentData(response.data);
                setIsLoading(false)
            })
            .catch((error) =>
            {
                console.log("NETWORK ERROR", error);
            }).finally(() =>
            {
                setIsLoading(false)
            })
    };
    const printAdmission = () =>
    {
        setShowAdmissionLetter(true);
        setTimeout(() =>
        {
            handleAdmissionPrint();
            setShowAdmissionLetter(false);
        }, 100);

    }

    const handleAdmissionPrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const getAdmissionLetter = async (app_id) =>
    {
        toast.info('donwloading admission letter, please wait...')
        let course_code;
        let app_info = []
        let faculty_det = []
        let desc = {}
        try
        {
            axios.get(`${serverLink}registration/admissions/ug/information/${app_id}`)
                .then(async (response) =>
                {
                    course_code = response.data.course[0].CourseCode;
                    app_info = response.data;
                    setappInfo(response.data);
                })
                .then(async () =>
                {
                    // setTimeout(async() => {
                    await axios.get(`${serverLink}registration/admissions/ad-faculty/${course_code}`,)
                        .then((result) =>
                        {
                            if (result.data.length > 0)
                            {
                                faculty_det = result.data;
                                setfacultyDetails(result.data);
                            }
                        })
                    // }, 200);
                }).then(async () =>
                {
                    await axios.get(`${serverLink}registration/admissions/admission-letter/${app_id}`,)
                        .then((result) =>
                        {
                            if (result.data.length > 0)
                            {
                                const cons = result.data[0].AdmissionCondition.split(", ");
                                desc = {
                                    level: result.data[0].AdmissionLevel,
                                    admissionSemester: result.data[0].AdmissionSchoolSemester,
                                    semester: result.data[0].AdmissionSemester,
                                    CourseName: result.data[0].CourseName,
                                    courseCode: result.data[0].AdmissionCourse,
                                    con1: cons[0],
                                    con2: cons[1],
                                    con3: cons[2],
                                    con4: cons[3],
                                    con5: cons[4],
                                    con6: cons[5],
                                    type: result.data[0].AdmissionType,
                                }
                            }
                            const sendData = {
                                applicantInfo: app_info.applicant_data,
                                applicantCourse: app_info.course,
                                decison: desc,
                                school: {
                                    logo: projectLogo,
                                    name: projectName,
                                    address: projectAddress,
                                    email: projectEmail,
                                    phone: projectPhone,
                                    shortCode: shortCode,
                                    viceChancellor: projectViceChancellor
                                },
                                facultyDetails: faculty_det,
                                appInfo: app_info,
                            }
                            setSendData(sendData);
                            setAdmissionLetter(result.data)
                            setTimeout(() =>
                            {
                                printAdmission();
                            }, 2000);
                        })
                })
        } catch (error)
        {
            toast.error("Network error, please try again")
        }
    }
    const onApplyNow = async (course) =>
    {
        const sendData = {
            ApplicantID: userData.EntryID,
            ApplicationType: course.ApplicationType,
            CourseCode: course.CourseCode,
        };
        toast.info("Processing... Please wait");

        await axios
            .post(`${serverLink}application/apply_now`, sendData)
            .then((response) =>
            {
                const message = response.data.message;
                if (message === "success")
                {
                    toast.success("Course Selected Successful");
                    handleCloseApplicationModal();
                    getApplicationRecord();

                    // sendEmail(
                    //     userData.EmailAddress,
                    //     `Thank you for your application to the ${projectName}`,
                    //     "Application for Admission",
                    //     `${userData.FirstName} ${userData.MiddleName} ${userData.Surname}`,
                    //     `Thank you for starting an application for admission to study at ${projectName}. <br/>
                    //  If you do not have all the required information or documents to submit your application at this time, you can always log back in at ${projectURL}admission/application using you email address and password. <br/>
                    // `,
                    //     `Best Regards <br/> Admission Team <br/> <a target="_blank" href=${projectURL}>${projectURL}</a>`
                    // );
                } else if (message === "record exist")
                {
                    toast.error(
                        "You have a pending application awaiting decision for the course you've selected"
                    );
                } else
                {
                    toast.error(
                        "Something went wrong. Please check your network and try again!"
                    );
                }
            })
            .catch((error) =>
            {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() =>
    {
        if (props.applicationLogin === "") navigate("/admission/application");
    }, [props.applicationLogin]);

    useEffect(() =>
    {
        getApplicationRecord();
    }, []);

    const handleLogout = () =>
    {
        props.setOnApplicationLoginDetails("");
        props.setOnApplicationDataDetails("");
        navigate("/admission/application");
    };

    return isLoading ? (<Loader />) : (
        props.applicationLogin !== "" && (
            <>
                <PageName page={"Dashboard"} />
                <Modal
                    show={showApplicationModal}
                    onHide={handleCloseApplicationModal}
                    size="lg"
                >
                    <Modal.Header >
                        <Modal.Title>Apply Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.courseList.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Course Name</th>
                                            {/*<th>Code</th>*/}
                                            {/*<th>Duration</th>*/}
                                            {/*<th>Type</th>*/}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.courseList.map((course, index) =>
                                        {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{course.CourseName}</td>
                                                    {/*<td>{course.CourseCode}</td>*/}
                                                    {/*<td>*/}
                                                    {/*    {course.Duration} {course.DurationType}*/}
                                                    {/*</td>*/}
                                                    {/*<td>{course.ApplicationType}</td>*/}
                                                    <td>
                                                        <button
                                                            className="btn btn-secondary btn-sm"
                                                            onClick={() => onApplyNow(course)}
                                                        >
                                                            Apply
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="alert alert-info">NO COURSE FOUND!</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleCloseApplicationModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="graduate-admission pb-70 mb-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h4>Welcome, {userData.FirstName}</h4>
                                    </div>
                                    <div className="widget-categories">
                                        <ul className="list-unstyled list-style mb-0">
                                            <hr />
                                            <li >
                                                <Link className="d-flex" to={"/admission/application/dashboard"}>
                                                    <span className={'text-dark'}>Dashboard </span>
                                                    <i className="fas fa-chevron-right text-right text-danger ml-auto" />
                                                </Link>
                                            </li>
                                            <hr />

                                            <li >
                                                <Link className="d-flex" onClick={handleLogout} to={""}>
                                                    <span className={'text-dark'}>Logout </span>
                                                    <i className="fas fa-chevron-right text-right text-danger ml-auto" />
                                                </Link>
                                            </li>
                                            <hr />

                                        </ul>
                                    </div>
                                </div>

                                <div className="widget row pb-4 mb-5 mt-4">
                                    <div className="col-lg-12 col-sm-12">
                                        <button className="btn btn-sm btn-info w-100" style={{ borderRadius: "10px" }} onClick={handleShowApplicationModal}>
                                            Apply Now
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9">
                                <div className="instructor">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="instructor-content">
                                                <div className="name">
                                                    <h3>{`${userData.FirstName} ${userData.MiddleName} ${userData.Surname}`}</h3>
                                                    <p>{userData.Occupation}</p>
                                                </div>
                                                <div className="mb-30 table-responsive">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th>Email Address</th>
                                                                <td>{userData.EmailAddress}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <td>{userData.PhoneNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Date Of Birth</th>
                                                                <td>
                                                                    {formatDate(userData.DateOfBirth)}
                                                                    {/* {formatDateAndTime(
                                                                        userData.DateOfBirth,
                                                                        "date"
                                                                    )} */}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Gender</th>
                                                                <td>{userData.Gender}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Address</th>
                                                                <td>{userData.Address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>State</th>
                                                                <td>{userData.StateOfOrigin}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Local Government</th>
                                                                <td>{userData.LGA}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Nationality</th>
                                                                <td>{userData.Nationality}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Religion</th>
                                                                <td>{userData.Religion}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h3>Application History</h3>

                                    <div className="table-responsive">
                                        {applicationData.length > 0 ? (
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>AppID</th>
                                                        <th>Course</th>
                                                        <th>Type</th>
                                                        <th>Application Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {applicationData.map((app, index) =>
                                                    {
                                                        let paid = paymentData.filter(x => parseInt(x.StudentID) === parseInt(app.EntryID))
                                                        let status = "";
                                                        let action = "--";
                                                        let enrol = "--";
                                                        if (app.Status === 0)
                                                        {
                                                            status = (
                                                                <span className="badge badge-warning">
                                                                    Not Submitted
                                                                </span>
                                                            );
                                                            action = (
                                                                <button
                                                                    className="btn btn-info btn-sm"
                                                                    onClick={async () =>
                                                                    {
                                                                        await props.setOnApplicationDataDetails(app)
                                                                        toast.warning("please wait")
                                                                        setTimeout(() =>
                                                                        {
                                                                            window.location.href = `/admission/application/${app.ApplicationType}`
                                                                        }, 200);
                                                                    }

                                                                    }
                                                                >
                                                                    view
                                                                </button>
                                                            );
                                                        } else if (app.Status === 1)
                                                        {
                                                            status = (
                                                                <span className="badge badge-info">
                                                                    Submitted
                                                                </span>
                                                            );
                                                        } else if (app.Status === 2 && paid.length === 0)
                                                        {
                                                            status = (
                                                                <span className="badge badge-success">
                                                                    Approved
                                                                </span>
                                                            );
                                                            action = (
                                                                <>
                                                                    <Link className="btn btn-sm btn-success" to={`/admission-letter/${encryptData(`q=` + app.EntryID)}`} >
                                                                        print
                                                                    </Link>
                                                                </>
                                                            );
                                                            enrol = (
                                                                <a
                                                                    href={`https://payment.cosmopolitan.edu.ng`}
                                                                >
                                                                    <button
                                                                        className="btn btn-primary btn-sm"
                                                                    >
                                                                        Pay Now
                                                                    </button>
                                                                </a>
                                                            );
                                                        } else if (app.Status === 2 && paid.length > 0)
                                                        {
                                                            status = (
                                                                <span className="badge badge-success">
                                                                    Approved
                                                                </span>
                                                            );
                                                            action = (
                                                                <>
                                                                    <Link className="btn btn-sm btn-success" to={`/admission-letter/${encryptData(`q=` + app.EntryID)}`} >
                                                                        print
                                                                    </Link>
                                                                </>
                                                            );
                                                            enrol = (
                                                                <Link
                                                                    to={`/admission/application/enrolment`}
                                                                >
                                                                    <button
                                                                        className="btn btn-info btn-sm"
                                                                        onClick={() =>
                                                                            props.setOnApplicationDataDetails(app)
                                                                        }
                                                                    >
                                                                        Enrolment
                                                                    </button>
                                                                </Link>
                                                            );
                                                        } else if (app.Status === 4)
                                                        {
                                                            status = (
                                                                <span className="badge badge-success">
                                                                    Approved
                                                                </span>
                                                            );
                                                            action = (
                                                                <>
                                                                    <Link className="btn btn-sm btn-success" to={`/admission-letter/${encryptData(`q=` + app.EntryID)}`} >
                                                                        print
                                                                    </Link>
                                                                </>
                                                            );
                                                            enrol = (
                                                                <Link
                                                                    to={`/admission/application/enrolment`}
                                                                >
                                                                    <button
                                                                        className="btn btn-primary btn-sm"
                                                                        onClick={() =>
                                                                            props.setOnApplicationDataDetails(app)
                                                                        }
                                                                    >
                                                                        Enrolment
                                                                    </button>
                                                                </Link>
                                                            );
                                                        } else
                                                        {
                                                            status = (
                                                                <span className="badge badge-danger">
                                                                    Rejected
                                                                </span>
                                                            );
                                                        }

                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{app.EntryID}</td>
                                                                <td>{app.CourseName}</td>
                                                                <td>{app.ApplicationType}</td>
                                                                <td>
                                                                    {formatDateAndTime(
                                                                        app.InsertedDate,
                                                                        "date"
                                                                    )}
                                                                </td>
                                                                <td>{status}</td>
                                                                <td>
                                                                    {action}
                                                                </td>
                                                                <td>{enrol}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="alert alert-info">
                                                NO APPLICATION HISTORY FOUND
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* {
                    showAdmissionLetter && <AlAnsarAdmissionLetter componentRef={componentRef} data={sendData} />
                } */}
            </>
        )
    );
}

const mapStateToProps = (state) =>
{
    return {
        applicationLogin: state.ApplicationLoginDetails,
        courseList: state.CourseListDetails,
    };
};
const mapDispatchToProps = (dispatch) =>
{
    return {
        setOnApplicationLoginDetails: (p) =>
        {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnApplicationDataDetails: (p) =>
        {
            dispatch(setApplicationDataDetails(p));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationDashboard);
